import { ROLE_ADMIN } from '~/const/user/roles'
import { defineNuxtRouteMiddleware, navigateTo } from '#app'
import { useAuthStore } from '~/stores/auth'

export default defineNuxtRouteMiddleware((to) => {
    const authorizedRoleIds = [ROLE_ADMIN]
    const auth = useAuthStore()

    if (!auth.authenticated) {
        return navigateTo('/login')
    }

    if (!authorizedRoleIds.includes(auth.user.role)) {
        return navigateTo('/')
    }
})
